.container {
    /* https://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
    box-sizing: border-box;

    *, 
    *::before, 
    *::after {
        box-sizing: inherit;
    }
    
    cursor: text;
    overflow-y: auto;
    height: 200px;

    padding: 4px;

    background-color: #fff;

    border: 1px solid #ccc;
    border-radius: 4px;

    // remove focuse border style of mac sysytem
    // outline: none; 
    outline: 0;

    &.focused {
        border-color: #0096cc;
    }
    &&.errors {
        border-color: #db3d44;
    }

    transition: all 0.2s linear 0s;

    .token-list {
        .token {
            float: left;

            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            align-content: center;

            max-width: 100%;
            // TODO: Set minimum?
            // min-width: 100px;

            height: 32px;
            line-height: 1.5;
            white-space: nowrap;
            
            margin: 0 4px 4px 0;
            padding: 0; 

            border: 0;
            border-radius: 4px;

            color: #0096cc;
            background-color: #cceaf5;

            &.error {
                // color: #f75757;
                color: #db3d44;
                background-color: #f9b5b5;
            }

            &:hover {
                cursor: pointer;
                background-color: #e6f4fc;

                &.error {
                    background-color: #ffdada;
                }

                &.read-only {
                    cursor: default;
                }
            }

            &.active {
                .autosized-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;

                    margin: 4px 8px;
                    height: 32px;

                    input {
                        height: auto;
                        border-bottom: 1px solid #aaa;
                    }
                }
            }
            
            // &.read-only {
            //     .label-wrapper {
            //         max-width: 100%;
            //     }
            // }
            
            .label-wrapper {
                flex: 1 0 0;

                margin: 0 8px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
            .delete-button {
                flex: 0 0 0;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;

                height: 100%;
                padding: 2px 8px;

                cursor: pointer;
                // font-size: 14px;
                // color: #222;
                opacity: 0.3;

                border-left: 1px solid #aaa;
                border-radius: 0 4px 4px 0;

                &:hover {
                    opacity: 1;
                    background-color: #aaa;

                    .close-icon {
                        &:before,
                        &:after {
                            border-color: #fff;
                        }
                    }
                }

                .close-icon {
                    width: 14px;
                    height: 14px;
                    position: relative;

                    &:before,
                    &:after {
                        content: '';
                        height: 14px;
                        left: 6px;
                        border-left: 2px solid #222;
                        position: absolute;
                    }
                    &:before {
                        transform: rotate(-45deg);
                    }
                    &:after {
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }

    .autosized-wrapper {
        float: left;
        overflow: hidden;
        margin-right: 4px;
        
        input {
            height: 32px;
            margin: 0;
            padding: 0;

            border: 0;

            // remove focuse border style of mac sysytem
            // outline: none; 
            outline: 0;

            cursor: text;
            box-shadow: none;
            background: none;
            color: #222;
        }
    }
}
